// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
/* require("channels") */
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images/icons/inline', true)
// const imagePath = (name) => images(name, true)

import './stylesheets/application.scss';

import '../application/hover_titles.js';
import '../shared/user.js';
// import '../application/quota_circle.js';
import '../application/show_flash_messages.js';
import '../shared/modular_popin.js';

console.log("application pack");
